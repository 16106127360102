let shouldCollapseSidebarOnResize = window.innerWidth < 768
let isMobileFilterOpen = false

function hideOrShowFilter(isOpen) {
  const el = document.querySelector('.recipelist__results')
  const sideBar = document.querySelector('.recipelist__sidebar')
  isMobileFilterOpen = isOpen
  if (isOpen) {
    el.classList.add('d-none')
    sideBar.classList.add('open')
  } else {
    el.classList.remove('d-none')
    sideBar.classList.remove('open')
  }
}

function hideSubmitButton() {
  // if we've got js, then get rid of the submit button, this form auto submits on changes.
  // This lives in a function because otherwise when hitting the back button in the browser, sometimes
  // filterSubmitButton is already defined and then we get js exceptions
  const filterSubmitButton = document.querySelector('#ingredient-filter-form button[type=submit]')
  filterSubmitButton.style.display = 'none'
  filterSubmitButton.disabled = true
}

function toggleChildCheckboxes(input) {
  const children = document.querySelectorAll(
    `.filter-form-child-group[data-childrenfor=${input.id}] input[name=ingredients]`,
  )
  for (const childInput of children) {
    childInput.checked = false
    childInput.indeterminate = false
  }
}

function toggleParentCheckboxes(input) {
  // get the wrapper "childrenfor" element, grab it's childrenfor value
  // and then set that element to checked=False, indeterminate=True
  const childGrouper = input.closest(`.filter-form-child-group`)
  if (childGrouper) {
    const parentId = childGrouper.dataset.childrenfor
    const parent = document.querySelector(`#${parentId}`)
    if (parent) {
      let noChildrenChecked = true
      if (!input.checked) {
        const siblingInputs = childGrouper.querySelectorAll('input[name=ingredients]')
        // TODO: if all children are checked, then uncheck them and check the parent
        // TODO: possibly, if parent is checked, then check all children and on form submit
        //       clean up the extra unneeded params. We only need to send that the parent was checked
        //       to the server.
        for (const sibling of siblingInputs) {
          if (sibling.checked) {
            noChildrenChecked = false
          }
        }
      }
      parent.indeterminate = !noChildrenChecked || input.checked
      parent.checked = false
      toggleParentCheckboxes(parent)
    } // end if parent
  }
}

function resizeFn() {
  if (window.innerWidth >= 768) {
    document.querySelector('.recipelist__sidebar__filters').open = true
    isMobileFilterOpen = false
    shouldCollapseSidebarOnResize = true
    hideOrShowFilter(false)
  } else if (shouldCollapseSidebarOnResize) {
    document.querySelector('.recipelist__sidebar__filters').open = false
    shouldCollapseSidebarOnResize = false // don't do it on EVERY resize, just the first time we go from >= 768 to < 768;
    hideOrShowFilter(false)
  }
}

function initPage() {
  hideSubmitButton()
  const ingredientFilterCheckboxes = document.querySelectorAll(
    '.ingredient-filters input[name=ingredients]',
  )
  const unchecked = document.querySelectorAll('input[name="ingredients"]:not(:checked)')

  for (const input of ingredientFilterCheckboxes) {
    input.addEventListener('click', () => {
      toggleChildCheckboxes(input)
      toggleParentCheckboxes(input)
      input.dispatchEvent(new Event('submitFilterForm'))
    })
  }

  // find any unchecked inputs and see if they have any checked children. If so, mark as indeterminate.
  // There must be something more efficient, but this works for now. It does result in a small amount of flash
  // as the property gets set, but there doesn't seem to be anything to do about that.
  for (const input of unchecked) {
    const child = document.querySelector(
      `.filter-form-child-group[data-childrenfor=${input.id}] input[name="ingredients"]:checked:last-child`,
    )
    input.indeterminate = !!child
    if (input.indeterminate) {
      const details = input.closest('details')
      details.open = true
    }
  }

  window.onresize = resizeFn
  resizeFn()
}

initPage()

document.querySelector('.recipelist__sidebar__filters summary').addEventListener('click', () => {
  hideOrShowFilter(!isMobileFilterOpen)
})
